$font-family-sans-serif-loaded: 'Montserrat', sans-serif;
$font-family-alt-loaded: "Oswald", sans-serif;

.wf-active {
  h1, h2, h3, h4, h5, h6,
  .ff--alt, .ff--alt--light {
    font-family: $font-family-alt-loaded;
    font-weight: 400;
  }
  .ff--alt--light {
    font-weight: 300;
  }
  body {
    font-family: $font-family-sans-serif-loaded;
  }
}
