@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon.eot?3li5gh");
  src: url("/assets/fonts/icomoon.eot?3li5gh#iefix") format("embedded-opentype"),
    url("/assets/fonts/icomoon.ttf?3li5gh") format("truetype"),
    url("/assets/fonts/icomoon.woff?3li5gh") format("woff"),
    url("/assets/fonts/icomoon.svg?3li5gh#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-anterior:before {
  content: "\e900";
}
.icon-logotipo-cmtv:before {
  content: "\e901";
}
.icon-mais:before {
  content: "\e902";
}
.icon-menos:before {
  content: "\e903";
}
.icon-seguinte:before {
  content: "\e904";
}
.icon-simbolo-investir:before {
  content: "\e905";
}
.icon-rede-cmtv:before {
  content: "\e906";
  color: #989898;
  margin-right: 10px;
}
