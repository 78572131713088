.footer {
  margin-top: 80px;

  .container-fluid {
    background: $black;
    color: $gray;
    padding: 60px;

    font-size: 14px;

    .text-by {
      margin-top: 40px;
      a {
        color: $gray;
        &:hover {
          color: $green;
          text-decoration: underline;
        }
      }
    }

    .footer-first-row {
      margin-bottom: 60px;
    }

    .link-footer {
      color: #989898;
      transition: 0.25s;
      text-decoration: none;

      &:hover {
        color: #cecece;
      }
    }

    .other-sites-title-container {
      margin-top: 60px;
      .other-sites-title {
        letter-spacing: 2px;
        color: #989898;
      }
    }

    .sites-container {
      margin-top: 20px;
      margin-bottom: 20px;
      line-height: 28px;
      padding-left: 15px;
      .px-0 {
        padding-right: 0px;
        padding-left: 0px;
      }
    }
  }
}
