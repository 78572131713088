h1, .h1 {
  font-size: 62px;
  font-weight: 700;
}

h2, .h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}

h3, .h3 {
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0.01em;
}

h4, .h4 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.01em;
}

h5, .h5 {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

h6, .h6 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
}

p, .h7, .text-static {
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.01em;
}

.h8 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.01em;
}

.text-green {
  color: $green;
}

.text-green-dark {
  color: $green-dark;
}

.text-gray {
  color: $gray;
}
