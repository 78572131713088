.page-header {
  margin: 0;
  width: 100%;
  height: 400px;
}

.page-right-col {
  background: $white;
  position: relative;
  top: -150px;

  padding: 100px;

  margin-bottom: -150px;
}

.page-incentive-teaser-container {
  padding: 60px 40px;
  border: 5px solid $black;

  font-family: 'Merriweather', serif;
  font-style: italic;

  font-size: 15px;

  line-height: 1.8em;

  p {
    margin: 0;
  }
}

.page-title {
  margin-top: 100px;
}

.page-torresvedras-parallax {
  text-align: center;
  padding: 100px 0;

  background:url('/assets/img/imagens/torres-vedras-2-parallax.jpg');

  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  margin-bottom: 100px;

  color: $white;

  font-family: 'Merriweather', serif;

  p {
    letter-spacing: 0.01em;
    font-size: 18px;

    line-height: 1.8em;

    font-style: italic;
  }
}

.page-content {
  margin-top: 70px;
}

.table-bordered {
  td {
    padding: 8px;
    vertical-align: top;
  }
}