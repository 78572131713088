.program-logo {
  width: 500px;
  height: 500px;
  background-color: #F5F5F5;

  padding: 0;

  position: relative;
  top: -150px;

  a {
    width: 500px;
    height: 500px;
    display: block;
  }
}

.nav-program {
  padding-top: 50px;
  &>li {
    &>a {

      padding: 10px 15px;

      font-size: 16px;
      color: $gray;
      background: none;
      &:hover, &.active {
        color: $black;
        background: none !important;

        &:after {
          z-index: 9999;
          content: ' ';
          display: block;
          width: 70px;
          border-bottom: 1px solid $gray-dark;
          position: absolute;
          right: -70px;
          top: 20px;
        }

      }
    }
  }
}

.program-home-title {
  padding-top: 50px;
}

.content-wrap {

  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.01em;
  width: 100%;

  .content-image{

    float: right;
    margin: -300px 0 20px 20px;
    width: 500px;
    height: 500px;
    background-color: #F5F5F5 !important;

  }

}