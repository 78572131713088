.navbar-top {
  color: $black;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;

  .nav > li {

    &:after {
      content:'\00B7';
    }

    &:last-child:after {
      content:'';
    }

    & > a {
      display: inline;
      text-decoration: none;
      background-color: transparent;
      color: $black;
    }
  }

  .nav > li > a:hover, .nav > li > a:focus, .nav > li.active > a {
    text-decoration: none;
    background-color: transparent;
    color: $green-dark;
  }

  h1 {
    margin: 0;
  }

  .navbar-nav {
    margin-top: 35px;
  }

}