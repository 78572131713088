.contacto-right {
    background: #F5F5F5 !important;
    padding: 80px;

    text-align: center;

    p {
        margin-top: 25px;
    }

    .form-inline {
        text-align: center;

        input {
            background: transparent;
            border-radius: 0;
            border: none;
            border-bottom: 4px solid $black;
            box-shadow: none;
            text-align: center;

            width: 100%;
        }

        .form-group {
            margin-bottom: 25px;
            display: block;
        }

        .form-group-button {
            margin-top: 40px;
        }
    }
}