.incentive-title {

  margin-top: 50px;


  a {
    color: $black;

    .icon-mais {
      color: $gray;
    }

    &:hover, &:focus {
      color: $green;
      text-decoration: none;
      .icon-mais {
        color: $green;
      }
    }

    .icon-mais, .icon-menos {
      float: right;
      font-size: 30px;
    }
  }


}