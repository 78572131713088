.news-home-title {
  margin-top: -110px;
  margin-bottom: 60px;
}
.news-date {
  font-size: 12px;
  color: $gray;
}

.news-btn-read-more {
  border-radius: 50px 50px 50px 50px;
  border: 1px solid $green-dark;
  color: $green-dark;
  background: transparent;
  transition: all 0.5s;

  text-transform: uppercase;

  font-size: 9px;

  letter-spacing: 0.3em;

  padding: 10px 25px;

  &:hover {
    color: $white;
    border: 1px solid $green;
    background: $green;
  }
}

.news-home-item-container {
  height: 220px;
  overflow: hidden;
  margin-bottom: 25px;

  .news-home-item-title a {
    color: $black;
    &:hover {
      text-decoration: none;
      color: $green-dark;
    }
  }

  p {
    line-height: 1.5em;
  }
}

.news-home-item-container__auto {
  height:auto;
  overflow: none;
}

.news-detail-container {
  margin: 70px 0;
}

.news-detail-image {
  width: 100%;
  margin-bottom:40px;
  margin-top: 30px;
}

.news-list-right {
  background-color: #F5F5F5 !important;
  padding: 100px 0px;

  -webkit-flex: 1;
  flex:1;
  position:relative;

  .news-list-right-container {
    padding:0 80px;
  }

  .news-list-right-container-overflow {
    position:absolute;
    width:100%;
    height:calc(100% - 280px);
    overflow-y: scroll;
    top: 180px;
  }

}


.news-list-right-item {
  display: block;
  margin-top: 60px;
}

.news-nav-arrow {
  margin-top: 50px;
  a {
    font-size: 40px;
    color: $gray-light;
    &:hover {
      color: $green;
      text-decoration: none;
    }
  }
}




.flex-row {
  display: flex;
  flex-wrap: wrap;
}
.flex-row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}
.flex-row.row:after,
.flex-row.row:before {
  display: flex;
}
