/* Slider */
.slick-slider
{
  position: relative;

  display: block;

  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list
{
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus
{
  outline: none;
}
.slick-list.dragging
{
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track
{
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after
{
  display: table;

  content: '';
}
.slick-track:after
{
  clear: both;
}
.slick-loading .slick-track
{
  visibility: hidden;
}

.slick-slide
{
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide
{
  float: right;
}
.slick-slide img
{
  display: block;
}
.slick-slide.slick-loading img
{
  display: none;
}
.slick-slide.dragging img
{
  pointer-events: none;
}
.slick-initialized .slick-slide
{
  display: block;
}
.slick-loading .slick-slide
{
  visibility: hidden;
}
.slick-vertical .slick-slide
{
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.slick-item {
  height: 550px;
  background-size: cover;
}

.slick-arrow {
  position: absolute;
  top: 40%;

  background: none;
  border: none;
  outline:none;


  font-size: 40px;


  color: $white;

  background: none;
}

.slick-next {
  position: absolute;
  top: 48%;
  right: 50px;
}

.slick-prev {
  position: absolute;
  top: 48%;
  left: 50px;
  z-index: 999;
}



.slider-homepage {
  .icon-simbolo-investir {
    color: $green;
  }

  h1, h3 {
    color: $white;
  }

  h1 {
    position: relative;
    top: 180px;
    left: 50%;
  }

  h3 {
    position: relative;
    top: 160px;
    left: 50%;
    padding-left: 65px;
  }

  img {
    position: relative;
    top: 140px;
    left: 50%;
  }
}
